<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="articulo"
      :rules="formRules.articulo"
    >
      <b10-autocomplete
        v-model="form.articulo"
        :items="articulos"
        item-text="descripcion"
        label="Artículo"
        clearable
        :error-messages="errors"
        :search-input.sync="articuloSearch"
        return-object
        no-filter
        @change="articuloSelected"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title>{{ $online.articulo.title(slotProps.item) }}</v-list-item-title>
            <v-list-item-subtitle>{{ $online.articulo.subtitle(slotProps.item) }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <b10-autocomplete-alert
      v-if="form.articulo"
    >
      {{ $online.articulo.title(form.articulo) }}<br>{{ $online.articulo.subtitle(form.articulo) }}
    </b10-autocomplete-alert>
    <ValidationProvider
      v-slot="{ errors }"
      name="codigo"
      :rules="formRules.codigo"
    >
      <v-text-field
        v-model="form.codigo"
        flat
        label="Código"
        :error-messages="errors"
        clearable
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        flat
        label="Descripción"
        :error-messages="errors"
        clearable
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.inf_comercial"
      label="Información comercial"
    />
    <ValidationProvider
      v-if="isEdit"
      v-slot="{ errors }"
      name="precio"
      :rules="formRules.precio"
    >
      <v-text-field
        v-model="form.precio"
        class="number-input"
        flat
        label="Precio"
        :error-messages="errors"
        suffix="€"
        type="Number"
        clearable
        messages="Impuestos no incluidos"
      />
    </ValidationProvider>
    <v-text-field
      v-if="isEdit"
      v-model="form.dto"
      class="number-input"
      flat
      label="Descuento"
      type="Number"
      suffix="%"
    />
    <v-text-field
      v-model="form.unidades"
      class="number-input"
      flat
      label="Unidades"
      type="Number"
    />
    <v-text-field
      v-if="isEdit"
      v-model="importe"
      class="number-input"
      flat
      label="Importe total"
      suffix="€"
      type="Number"
      disabled
      messages="Impuestos no incluidos"
    />
    <v-checkbox
      v-model="form.opcional"
      label="Opcional"
    />
    <b10-textarea
      v-model="form.observacion"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './LpresupuestocliFormData'

export default {
  mixins: [formMixin],
  props: {
    idpresupuestocli: {
      type: [Number, String],
      default: null,
    },
  },
  data () {
    return {
      form: {
        articulo: null,
        codigo: null,
        descripcion: null,
        inf_comercial: null,
        precio: null,
        dto: null,
        unidades: 1,
        opcional: null,
        observacion: null,
        iva: null,
        recargo: null,
      },
      formRules: {
        articulo: { required: true },
        codigo: { required: true },
        descripcion: { required: true },
        precio: { required: true },
        dto: { required: true },
        unidades: { required: true },
        iva: { required: true },
        recargo: { required: true },
      },
      presupuestocli: {},
      articulos: [],
      articuloSearch: '',
    }
  },
  computed: {
    importe () {
      return this.$online.lpresupuestocli.importe(
        this.form.precio,
        this.form.dto,
        this.form.unidades
      )
    }
  },
  watch: {
    articuloSearch (value) {
      value && value.length >= 3 && value !== this.form.articulo && this.articuloDoSearch(value)
    },
  },
  async created () {
    const resp = await Data.selectLookups(this, this.idpresupuestocli)
    this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const lookupFormData = await Data.selectFormDataLookups(this, formData.idarticulo)
      this.articulos = lookupFormData.data.selectArticulo.result.dataset
      formData.articulo = lookupFormData.data.selectArticulo.result.dataset[0]
      this.form = formData
    },
    async articuloDoSearch (value) {
      this.articulos = await Data.selectArticuloSearch(this, value)
    },
    async articuloSelected (value) {
      if (value) {
        this.$set(this.form, 'codigo', value.codigo)
        this.$set(this.form, 'descripcion', value.descripcion)
        this.$set(this.form, 'inf_comercial', value.inf_comercial)
        if (this.presupuestocli.regimen_fiscal_con_iva) {
          this.$set(this.form, 'iva', value.valoriva)
        } else {
          this.$set(this.form, 'iva', 0)
        }
        if (this.presupuestocli.regimen_fiscal_con_recargo) {
          this.$set(this.form, 'recargo', value.valorrecargo)
        } else {
          this.$set(this.form, 'recargo', 0)
        }
      } else {
        this.$set(this.form, 'codigo', null)
        this.$set(this.form, 'descripcion', null)
        this.$set(this.form, 'inf_comercial', null)
        this.$set(this.form, 'iva', null)
        this.$set(this.form, 'recargo', null)
      }
    },
  },
}
</script>

<style scoped>
.number-input >>> input {
  text-align: right;
}
</style>
