var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"articulo","rules":_vm.formRules.articulo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.articulos,"item-text":"descripcion","label":"Artículo","clearable":"","error-messages":errors,"search-input":_vm.articuloSearch,"return-object":"","no-filter":""},on:{"update:searchInput":function($event){_vm.articuloSearch=$event},"update:search-input":function($event){_vm.articuloSearch=$event},"change":_vm.articuloSelected},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$online.articulo.title(slotProps.item)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$online.articulo.subtitle(slotProps.item)))])],1)]}}],null,true),model:{value:(_vm.form.articulo),callback:function ($$v) {_vm.$set(_vm.form, "articulo", $$v)},expression:"form.articulo"}})]}}])}),(_vm.form.articulo)?_c('b10-autocomplete-alert',[_vm._v(" "+_vm._s(_vm.$online.articulo.title(_vm.form.articulo))),_c('br'),_vm._v(_vm._s(_vm.$online.articulo.subtitle(_vm.form.articulo))+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"codigo","rules":_vm.formRules.codigo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"flat":"","label":"Código","error-messages":errors,"clearable":""},model:{value:(_vm.form.codigo),callback:function ($$v) {_vm.$set(_vm.form, "codigo", $$v)},expression:"form.codigo"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"descripcion","rules":_vm.formRules.descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"flat":"","label":"Descripción","error-messages":errors,"clearable":""},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}})]}}])}),_c('b10-textarea',{attrs:{"label":"Información comercial"},model:{value:(_vm.form.inf_comercial),callback:function ($$v) {_vm.$set(_vm.form, "inf_comercial", $$v)},expression:"form.inf_comercial"}}),(_vm.isEdit)?_c('ValidationProvider',{attrs:{"name":"precio","rules":_vm.formRules.precio},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"number-input",attrs:{"flat":"","label":"Precio","error-messages":errors,"suffix":"€","type":"Number","clearable":"","messages":"Impuestos no incluidos"},model:{value:(_vm.form.precio),callback:function ($$v) {_vm.$set(_vm.form, "precio", $$v)},expression:"form.precio"}})]}}],null,false,1204343513)}):_vm._e(),(_vm.isEdit)?_c('v-text-field',{staticClass:"number-input",attrs:{"flat":"","label":"Descuento","type":"Number","suffix":"%"},model:{value:(_vm.form.dto),callback:function ($$v) {_vm.$set(_vm.form, "dto", $$v)},expression:"form.dto"}}):_vm._e(),_c('v-text-field',{staticClass:"number-input",attrs:{"flat":"","label":"Unidades","type":"Number"},model:{value:(_vm.form.unidades),callback:function ($$v) {_vm.$set(_vm.form, "unidades", $$v)},expression:"form.unidades"}}),(_vm.isEdit)?_c('v-text-field',{staticClass:"number-input",attrs:{"flat":"","label":"Importe total","suffix":"€","type":"Number","disabled":"","messages":"Impuestos no incluidos"},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}}):_vm._e(),_c('v-checkbox',{attrs:{"label":"Opcional"},model:{value:(_vm.form.opcional),callback:function ($$v) {_vm.$set(_vm.form, "opcional", $$v)},expression:"form.opcional"}}),_c('b10-textarea',{attrs:{"label":"Observaciones"},model:{value:(_vm.form.observacion),callback:function ($$v) {_vm.$set(_vm.form, "observacion", $$v)},expression:"form.observacion"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }