import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idpresupuestocli) {
    let apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormDataLookups (Vue, idarticulo) {
    const apiFilterArticulo = new APIFilter()
      .addExact('idarticulo', idarticulo)
    let apiCalls = []
    apiCalls.push({ name: 'selectArticulo', method: 'articulo.select', params: { filter: apiFilterArticulo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectArticuloSearch (Vue, search) {
    const apiFilter = new APIFilter()
      .addExact('estado', 1)
    apiFilter.addNestedFilter(Vue.$online.articulo.searchFilter(search))
    const resp = await Vue.$api.call(
      'articulo.select',
      {
        filter: apiFilter,
        page: 0,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
      .addExact('idlpresupuestocli', id)
    const resp = await Vue.$api.call('lpresupuestocli.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
